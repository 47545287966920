<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">分销管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 class="flexcb doedit" style="padding-bottom: 20px">
              <span>课程基本信息</span>
            </h3>
            <div class="form-detail">
              <el-form :inline="true" ref="ruleForm" label-width="10rem">
                <el-form-item label="课程名称：" class="form-inline">
                  <p>{{ ruleForm.courseName }}</p>
                </el-form-item>
                <el-form-item label="商品分类：" class="form-inline">
                  <p>{{ ruleForm.courseType || "" }}</p>
                </el-form-item>
                <el-form-item label="原价：" class="form-inline">
                  <p>{{ ruleForm.yj || "" }}</p>
                </el-form-item>
                <el-form-item
                  label="课程详情："
                  prop="context"
                  class="form-inline"
                >
                  <p>{{ ruleForm.context }}</p>
                </el-form-item>
                <el-form-item label="课程图片：" prop="thumbnail">
                  <div class="img-el-upload" style="width: 256px">
                    <el-image
                      :src="
                        ruleForm.thumbnail || require('@/assets/develop.png')
                      "
                      fit="contain"
                    ></el-image>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <h3 style="padding-bottom: 20px">课程章节</h3>
            <div style="display: flex">
              <div style="flex: 1">
                <section class="chapterl-trees createdCourse">
                  <el-tree
                    :data="data"
                    node-key="id"
                    default-expand-all
                    :draggable="draggable && !disabled"
                    :allow-drop="allowDrop"
                    :allow-drag="allowDrag"
                    v-if="data.length"
                    @node-drop="handleDrop"
                  >
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <p class="flexac" v-if="data.level === 1">
                        <span style="width: 44px; text-align: left">
                          <span class="level" style="margin-right: 10px">
                            <!-- 一级 -->
                            {{ data.chapterNum }}
                          </span>
                        </span>

                        <span
                          :title="data.catalogName"
                          class="ellipsis"
                          style="margin-right: 20px"
                          >{{ data.catalogName }}</span
                        >
                      </p>
                      <template v-else-if="data.level === 2">
                        <draggable
                          class="tree-drag-group"
                          :group="{
                            name: 'people',
                            put: true,
                            pull: false,
                          }"
                          :sort="false"
                          @change="
                            (evt) => {
                              nodeChange(evt, data);
                            }
                          "
                          handle=".good"
                          :prevent-on-filter="true"
                        >
                          <span style="width: 60px; text-align: left">
                            <span
                              class="level"
                              v-if="styleType == '1'"
                              style="margin-right: 10px"
                            >
                              <!-- 二级 -->
                              {{ data.chapterNum }}
                            </span>
                            <span
                              class="level"
                              v-else
                              style="margin-right: 10px"
                            >
                              <!-- 一级 -->
                              {{ data.chapterNum }}
                            </span>
                          </span>
                          <span
                            :title="data.catalogName"
                            class="ellipsis"
                            style="margin-right: 3px; width: 203px"
                            >{{ data.catalogName }}</span
                          >
                          <span class="trees-btns">
                            <el-button
                              size="mini"
                              style="padding: 7px 10px"
                              @click.stop="() => showVideo(data)"
                              :disabled="disabled"
                              >预览</el-button
                            >
                          </span>
                        </draggable>
                      </template>
                    </div>
                  </el-tree>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2 :videoId="videoId" :videoTime="videoTime" v-if="videoType" />
      <player_1 :videoId="videoId" :videoTime="videoTime" v-else />
    </el-dialog>
    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import PaperShow from "@/views/resourse/popup/PaperShow";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../utils/swigger";
export default {
  name: "detailResource",
  components: {
    PaperShow,
    player_1,
    player_2,
  },
  data() {
    return {
      courseId: "",
      styleType: "",
      ruleForm: {
        courseName: "", // 商品名称
        courseType: "", // 商品分类
        yj: "", // 原价
        context: "", // 课程详情
        thumbnail: "", // 课程封面
      },
      // tree
      data: [],
      styleStu: false,
      chapterSchema: true,
      editShow: true,
      videoId: "", // 視頻id
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
    };
  },
  created() {
    if (this.$route.query.courseId) {
      this.courseId = this.$route.query.courseId;
    }
    if (this.$route.query.yj) {
      this.ruleForm.yj = this.$route.query.yj;
    }
    if (this.$route.query.courseType) {
      this.ruleForm.courseType = this.$route.query.courseType;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDetail();
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      distorySwigger();
    },
    getDetail() {
      this.$post(
        "/biz/ec/course/getCourseInfo",
        { courseId: this.courseId },
        3000,
        true,
        2
      ).then((ret) => {
        let ecCourseNodes = ret.data.ecCourseNodes;
        let isChapter = ret.data.isChapter;
        this.isChapter = ret.data.isChapter;

        if (isChapter) {
          this.styleType = 1;
          ecCourseNodes.map((el) => {
            el.level = 1;
            el.children.map((els) => {
              els.level = 2;
              els.progress = 100;
            });
            this.data = ecCourseNodes;
          });
        } else {
          this.styleType = 2;
          ecCourseNodes.map((el) => {
            console.log(el);
            let moreCourse = [];
            moreCourse.push({
              ind: new Date().getTime(),
              level: 2,
              catalogName: el.catalogName,
              courseOutlineId: el.courseOutlineId,
              isEdit: "0",
              videoId: el.videoId,
              section: el.section,
              trySee: el.trySee,
              progress: 100,
            });
            this.data = [...this.data, ...moreCourse];
          });
        }
        this.ruleForm.context = ret.data.ecCourse.courseDescription;
        this.ruleForm.courseName = ret.data.ecCourse.courseName;
        
        this.ruleForm.thumbnail = ret.data.promotePathUrl;
        this.mapData(this.data);
      });
    },
    section2Item(obj) {
      let children = [];
      if (obj.paperId) {
        children = [
          {
            ind: new Date().getTime(),
            paperId: obj.paperId,
            label: obj.paperName,
            level: 3,
          },
        ];
      }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted ? obj.kpointDeleted : "", // 课件是否删除了
          progress: 100,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: children,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
        };
      } else {
        return {
          ind: new Date().getTime(),
          label: obj.catalogName,
          id: obj.courseKpointId,
          level: 2,
          list: listArr,
          children: [],
          paperId: obj.paperId,
          paperName: obj.paperName,
          auditState: obj.auditState,
          auditReason: obj.auditReason,
        };
      }
    },
    // 预览
    showVideo(data) {
      this.$store.dispatch("handleVideoUrl", {
        kpointVideoId: data.videoId,
        kpointName: data.catalogName,
      });
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        if (item.children) {
          item.children.forEach((el, k) => {
            el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
          });
        }
      });

      this.$nextTick(() => {
        this.data = data;
      });
    },
  },
};
</script>
<style lang="less">
.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }
  .form-inline {
    width: 45%;
    
    .el-form-item__content {
        line-height: 40px;
      p {
        width: 15rem;
      }
    }
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.detail {
  .ellipsis {
    display: inline-block;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}
.doedit {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #6676ff;
  }
}
</style>
<style lang="less" scope>
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}
</style>
